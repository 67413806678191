import {createRouter, createWebHistory} from "vue-router";

const router = createRouter({
    history: createWebHistory(), // 有history 和 hash两种模式
    routes: [
        {
            name: 'index',
            path: '/',
            component: () => import('@/views/index/index.vue')
        },
        {
            name: 'applinks',
            path: '/applinks',
            component: () => import('@/views/applinks/index.vue')
        },
        {
            name: 'juBao',
            path: '/juBao',
            component: () => import('@/views/juBao/index.vue')
        }
    ]
})
export default router
